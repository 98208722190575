import styled from "styled-components"

export const HeaderStyle = styled.div`
	display: block;
	border-bottom: 1px solid #e1e1e1;
	background: #fff;
	z-index: 101;
	min-width: 1290px;
	box-sizing: border-box;
	${(props: { mode: "fixed" | "relative" }) =>
		props.mode === "fixed"
			? `
		position: fixed; left: 0px; right: 0px; top: 0px;
		@media screen and (max-width: 1199px) {
			position: relative;
		}
	`
			: ""}

	&:after {
		content: "";
		display: table;
		clear: both;
	}
	a,
	button {
		cursor: pointer !important;
		text-decoration: none;
	}
	> .top {
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	> .depth2 {
		border-top: 1px solid #e1e1e1;
	}
	> .bot {
		padding: 0 20px 0 32px;
		a {
			display: inline-block;
			padding: 15px 20px;
			position: relative;
			font-size: 15px;
			line-height: 18px;
			color: #a6a6a6;
			vertical-align: top;
			transition: background-color 0.15s ease;
			&:active:not(.off) {
				background-color: #e5e5e5;
			}
			&.on {
				font-weight: bold;
				color: #444;
				overflow: hidden;
				&:after {
					content: "";
					position: absolute;
					border-bottom: 2px solid #000;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
			&.off {
				color: #e1e1e1;
			}
			&.coming_soon {
				color: #e1e1e1;
				cursor: not-allowed !important;
				&:hover {
					color: #e1e1e1;
				}
			}
			&:hover {
				color: #444;
			}
		}
	}
	.logo-wrap {
		float: left;
		margin-right: 10px;
		width: 226px;
		height: 79px;
		a {
			display: block;
			cursor: pointer;
			width: 198px;
			height: 50px;
			padding: 15px 8px 14px 20px;
			.logo {
				width: 100%;
				height: 50px;
				object-fit: contain;
			}
		}
	}
	.gnb {
		float: left;
		position: relative;
		z-index: 1000;
		> ul {
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			> li {
				&:last-child {
					margin-right: 0;
				}
				display: block;
				float: left;
				position: relative;
				margin-right: 15px;
				cursor: pointer;
				> a {
					display: block;
					line-height: 18px;
					padding: 19px 15px 17px;
					span {
						display: block;
						font-size: 17px;
						color: #222222;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.53;
						letter-spacing: normal;
					}
					.small {
						height: 17px;
						font-size: 12px;
						color: #222222;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.42;
						letter-spacing: normal;
					}
				}
				.depth2_bx {
					margin-top: 1px;
					left: 0;
				}
				&:hover {
					.depth2_bx {
						visibility: visible;
						opacity: 1;
					}
					span {
						color: #ff681b;
					}
					.small {
						color: #ff681b;
					}
				}
				&.on {
					span {
						color: #ff681b;
					}
					.small {
						color: #ff681b;
					}
				}
			}
		}
		.new_ico {
			position: absolute;
			left: 65px;
			top: 20px;
			width: 40px;
		}
	}
	.top_btn {
		float: right;
		display: table;
		height: 80px;
		button,
		a {
			outline: none;
		}
		&.top_btn_login {
		}
		.top_btn_menu {
			display: table-cell;
			vertical-align: middle;
			&:last-child {
				> a {
					height: 39px;
					padding: 21px 21px 20px 13px;
					margin-left: 15px;
					&:hover {
						background-color: #e1e1e1;
					}
				}
			}
		}
		.bx_user,
		.agent_user_btn {
			display: inline-block;
			position: relative;
			button {
				display: inline-block;
				padding: 0;
				height: 24px;
				font-size: 12px;
				color: #222222;
				position: relative;
				text-align: center;
				box-sizing: border-box;
				border: 1px solid #e1e1e1;
				background: transparent;
				&:after {
					content: "";
					position: absolute;
					height: 20px;
					border-left: 1px solid #e1e1e1;
					left: -1px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
				&:first-child:after {
					content: none;
				}
			}
			button.i_account {
				min-width: 106px;
				height: 30px;
				border-radius: 4px;
				background-color: #eeeeee;
				padding: 5px 8px 6px;
				&:hover {
					background-color: rgba(0, 0, 0, 0.1);
				}
			}
			button.i_user {
				max-width: 216px;
				height: 30px;
				border-radius: 4px;
				background-color: #eeeeee;
				padding: 5px 24px 6px 8px;
				overflow: hidden;
				text-overflow: ellipsis;
				&:after {
					content: "";
					position: absolute;
					left: inherit;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 5px solid #222222;
					height: 0;
					bottom: 0;
					top: 0;
					right: 7px;
					margin: auto;
				}
			}
			.bx_wrap {
				position: relative;
				.depth2_bx {
					width: 130px;
					top: 0;
					left: 0;
				}
			}
			&:hover {
				button.i_user {
					background-color: rgba(0, 0, 0, 0.1);
				}
				.depth2_bx {
					visibility: visible;
					opacity: 1;
				}
			}
		}
		.agent_user_btn {
			display: flex;
			align-items: center;
			height: 100%;
			margin-right: 21px;
			margin-left: 27px;
			button.i_account {
				background-color: #fff;
				&:hover {
					background-color: #fff;
				}
			}
		}
		.i_link {
			display: inline-block;
			position: relative;
			color: #ffffff;
			background-color: #222222;
			width: 117px;
			height: 24px;
			font-size: 11px;
			font-weight: bold;
			line-height: 24px;
			text-align: center;
			margin-right: 24px;
		}
		.i_img_link {
			display: block;
			margin-left: 10px;
			img {
				display: block;
			}
		}
	}
	.depth2_bx {
		width: 189px;
		visibility: hidden;
		opacity: 0;
		z-index: 101;
		position: absolute;
		padding: 8px 0;
		min-width: 100%;
		background-color: #ffffff;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
		border: solid 1px #e1e1e1;
		transition:
			visibility 0.25s ease,
			opacity 0.25s ease;
		box-sizing: border-box;
		> a {
			line-height: 16px;
			font-size: 13px;
			color: #000000;
			padding: 9px 15px;
			display: block;
			white-space: nowrap;
			&:hover {
				background-color: #f6f6f6;
			}
			&.coming_soon {
				cursor: not-allowed !important;
				&:before {
					display: none;
					content: "Coming Soon!";
					position: absolute;
					color: #000000;
				}
				&:hover {
					color: transparent;
					&:before {
						display: block;
					}
				}
			}
		}
	}
	.apt_depth2 {
		overflow: hidden;
	}
`
